import React from 'react'

export default function UpNavbar() {
  return (
    <div className="navbar h-14 w-screen  flex  items-center px-10 sm:px-20 py-6 fixed top-0 z-50 bg-slate-700 justify-between">     
    <p className='font-[sentient] text-white  text-md sm:text-xl'>Customer Care Number : 7795453579</p>
    <p className='font-[sentient] text-yellow-500 text-md'>V 1.0.1</p>
  </div>
  )
}
