import React, { useState } from "react";
import logo from "..//..//Assets/logo.png";
import { HashLink } from 'react-router-hash-link';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const menuHandler = () =>{
    setIsOpen(!isOpen);
  }
  return (
    <>
    
    <div className="navbar h-16 w-screen  md:flex flex flex-row justify-between  px-10 md:px-20 py-6 fixed top-14 z-50 " >
      
      <img className="object-contain h-12 w-12" src={logo} alt="" />
      
      <div className="hidden md:block">
        <ul className="flex gap-6 font-[sentient] text-xl justify-between">
          <li className="px-5"><HashLink smooth to="/#products">Products</HashLink></li>
          <li className="px-5"><HashLink smooth to="/#hotdeals">🔥Hot Deals</HashLink></li>
          <li className="px-5"><HashLink smooth to="/#aboutuspage">About Us</HashLink></li>
          
          <li className="px-5"><HashLink smooth to="/#contact">Contact Us</HashLink></li>
          <li className="px-5"><HashLink smooth to="/#supportpage">Support</HashLink></li>
          
        </ul>
      </div>
      <div className="text-black  w-8 h-8 z-100 md:hidden" onClick={menuHandler}>
        
        { isOpen?"":
          <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path>
        </svg>
        }
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden md:flex-grow md:items-center md:w-auto relative`}>
       <div className="fixed left-0 top-0 w-full h-full bg-white z-200 flex items-center justify-center">
        <ul className="flex gap-6 font-[sentient] text-2xl justify-between flex-col">
          {/* <li className="px-5">Products</li>
          <li className="px-5">Hot Deals</li>
          <li className="px-5">About Us</li>
          <li className="px-5">Contact Us</li>
          <li className="px-5">Support</li> */}
           <li className="px-5" onClick={menuHandler}><HashLink smooth to="/#products">Products</HashLink></li>
          <li className="px-5" onClick={menuHandler}><HashLink smooth to="/#hotdeals">Hot Deals</HashLink></li>
          <li className="px-5" onClick={menuHandler}><HashLink smooth to="/#aboutuspage">About Us</HashLink></li>
          
          <li className="px-5"onClick={menuHandler} ><HashLink smooth to="/#contact">Contact Us</HashLink></li>
          <li className="px-5"onClick={menuHandler} ><HashLink smooth to="/#supportpage">Support</HashLink></li>
          
        </ul>

        <div className="h-10 w-10 absolute  top-2 right-2" onClick={menuHandler}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
        </div>
       </div>

      </div>
    </div>
    </>
  );
}

export default Navbar;
