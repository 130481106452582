import React from 'react'

export default function Aboutus() {
  return (
    <div className="aboutus bg-skyhero w-full flex flex-col items-center justify-center py-16 gap-10 " >
    <p className="font-[sentient] text-white text-5xl font-bold">About us</p>
    <p className="w-[90%] md:w-1/2 font-[Epilogue] text-white text-center">Shri Lakshmi Water Purifiers in Bangalore provides water purifiers and home appliances for domestic use. Their selection includes branded and non-branded water purifiers, as well as ACs, refrigerators, washing machines, and microwave ovens. They cater to customers in the Bengaluru area.</p>
  </div>
  )
}
