import React, { useContext, useEffect, useRef, useState } from "react";
import mouse from "..//..//Assets/scroller.svg";
import cardarrow from "..//..//Assets/cards-arrow.svg";
import kent from "..//..//Assets/kent.svg";
import kentbg from "..//..//Assets/kentrectangle.svg";
import ProductsCard from "./ProductsCard";
import HotdealsCards from "./HotdealsCards";
import hero from "..//..//Assets/heropage.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, EffectFade, Autoplay, Pagination } from "swiper/modules";

import kentb from "..//..//Assets/Brands/kent-3.svg";
import aquab from "..//..//Assets/Brands/aquaguard.svg";
import lgb from "..//..//Assets/Brands/lg-1.svg";
import crystalb from "..//..//Assets/Brands/ecocrystal-logo.png";
import aoSmith from "..//..//Assets/Brands/a-o-smith-seeklogo.svg";
import pureIt from "..//..//Assets/Brands/pureit-logo.png";
import syspure from "..//..//Assets/Brands/syspure.png";
import purosis from "..//..//Assets/Brands/purosis.png";
import Logocards from "./Logocards";
import offer1 from "..//..//Assets/Offers/offer1.png";
import offer2 from "..//..//Assets/Offers/offer2.png";
import popupImage from '..//..//Assets/popupImage.png';
import hotdeals1 from '..//..//Assets/hotdeals1.png'
import hotdeals2 from '..//..//Assets/hotdeals2.png'

//google maps apis //
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Footer from "./Footer";
import Aboutus from "./Aboutus";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import ProductContext from "../../Context/Context";
import Modal from "./Popupmodel";
import Loader from "./Loader";
const libraries = ["places"];


////////////////////////////////////////////////////////////////
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 22.562309, // default latitude
  lng: 87.408801, // default longitude
  //87.408801 22.562309
};

///////////////////////////////////////////////

const contactUsSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  phoneNumber: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Invalid phone number")
    .required("Phone number is required"),
  // alternatePhoneNumber: Yup.string()
  //   .matches(/^[6-9]\d{9}$/, "Invalid phone number")
  //   .required("Phone number is required"),
  // subject: Yup.string().required("Subject is required"),
  // issue: Yup.string().required("This field is required"),
});

const supportSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  phoneNumber: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Invalid phone number")
    .required("Phone number is required"),
  billNumber: Yup.number().required("bill number is required"),
  // subject: Yup.string().required("Subject is required"),
  // issue: Yup.string().required("This field is required"),
});

function Homepage() {
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyAXuaO-yfpb4Rax5R8iDsmlp_hDu8Tn4hs",
  //   libraries,
  // });
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const context = useContext(ProductContext);

  const { productList, productss ,Loading } = context;

  useEffect(() => {
    productList();
    console.log("use effect is running inside homepage");
  }, []);
  // useEffect(() => {
  //   productList();
  // }, [productList]);

  console.log(productss);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFile = (event) => {
    event.preventDefault();
    setDragging(false);
    const files = [...event.dataTransfer.files];
    console.log(files);
    setFile(files[0]);
  };

  // const [contactInfo , setContactIndo] =useState({
  //   fullName: '',
  //   phoneNumber: '',
  //   alternatePhoneNumber:'',
  //   subject: '',
  //   issue:''
  // })

  const contactForm = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      subject: "",
      issue: "",
    },
    validationSchema: contactUsSchema,

    onSubmit: (values, action) => {
      setLoading(true);
      console.log(values);

      const contactdetails = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        alternatePhoneNumber: values.alternatePhoneNumber,
        subject: values.subject,
        issue: values.issue,
      };
      console.log(loading);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "https://waterpurifier-9b87.onrender.com/api/contactUs",
        data: contactdetails,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(response);
          setLoading(false);
          navigate("./thankspage");
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            window.alert("Please fill right information..");
          }
          setLoading(false);
        });
      // action.resetForm();
      // setLoading(false);
    },
  });

  const supportForm = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      billNumber: "",
      subject: "",
      issue: "",
    },
    validationSchema: supportSchema,
    onSubmit: (values, action) => {
      setLoading(true);
      console.log(values);

      const newdata = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        billNumber: values.billNumber,
        subject: values.subject,
        issue: values.issue,
      };

      let data = new FormData();
      data.append("data", JSON.stringify(newdata));
      data.append("file", file);

      console.log(data.getAll("file"));

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "https://waterpurifier-9b87.onrender.tcom/api/support",
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          // window.alert("Applied for job successfully");
          // console.log(JSON.stringify(response.data));
          // console.log(fdata);
          // setFdata({});
          // console.log(fdata);
          // setapplyLoading(false);

          console.log(response.data);
          setLoading(false);
          navigate("./thankspage");
        })
        .catch((error) => {
          console.log(error);
          if (error) {
            window.alert("Please fill right information..");
            setLoading(false);
            // window.alert(error);
          }
          // setapplyLoading(false);
        });

      // console.log(errors);
      action.resetForm();
      // setFile(null);
    },
  });

  const brands = [
    {
      image: kentb,
    },
    {
      image: aquab,
    },
    {
      image: lgb,
    },
    {
      image: crystalb,
    },
    {
      image: aoSmith,
    },
    {
      image: pureIt,
    },
    {
      image: syspure,
    },
    {
      image: purosis,
    },
  ];


  const hotDeals = [
    {
      productName: "Air Purifier A",
      brand: "Brand A",
      description:
        "Figma ipsum component variant main layer. Ellipse image frame group background align hand align.",
      price: "11,600",
    },
    {
      productName: "Air Purifier B",
      brand: "Brand B",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      price: "9,000",
    },
    {
      productName: "Air Purifier C",
      brand: "Brand C",
      description:
        "Ut enim ad minim vencitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      price: "16,000",
    },
    {
      productName: "Air Purifier C",
      brand: "Brand C",
      description:
        "Ut enim ad minim vencitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      price: "16,000",
    },
    {
      productName: "Air Purifier C",
      brand: "Brand C",
      description:
        "Ut enim ad minim vencitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      price: "16,000",
    },
  ];

  const offersImgae = [
    {
      offerData: offer1,
    },
    {
      offerData: offer2,
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  
  console.log(productss);

  const filterProductsByCategory = (category) => {
    return productss.filter((product) => product.category == category);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
 
  console.log(selectedCategory);


  

  const filteredProducts = selectedCategory
    ? filterProductsByCategory(selectedCategory)
    : productss;

  console.log(filteredProducts);

  const productsButton = [
    {
      content:"Domestic Water Purifiers",
      id:1
    },
    {
      content:"Commercial Water Purifiers",
      id:2
    },
    {
      content:"Water Softners",
      id:3
    },
    {
      content:" Accessories",
      id:4
    },
    {
      content:"High pH Purifiers",
      id:5
    },
  
  ]

  const hotdealsPng = [
    {
      Image:hotdeals1
    },
    {
      Image:hotdeals2
    }
  ]

  const [isModalOpen, setIsModalOpen] = useState(true);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="bg-background  w-full md:px-20 px-10 relative pt-36">
      {/* <button onClick={openModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Open Image Popup
      </button> */}
      <div className="">
      <Modal isOpen={isModalOpen} onClose={closeModal} imageUrl={popupImage} />
      </div>
        
        <div className="offers lg:h-screen w-full z-0 ">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            navigation={false}
            pagination={{ clickable: true }}
            modules={[Autoplay, Navigation, EffectFade, Pagination]}
            className="mySwiper swiperWhy"
          >
            <div className=" h-3/4 w-3/4 flex justify-center items-center z-0" >
              {offersImgae.map((offers, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="w-full h-full flex items-center justify-center object-contain">
                      <img
                        className="w-full h-full object-contain  z-0"
                        src={offers.offerData}
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
          <div className="hidden w-full md:flex justify-center items-center md:pt-20 lg:pt-10">
            <img src={mouse} alt="" srcset="" />
          </div>
        </div>
        {/* <div className="hero md:h-screen w-full lg:pt-28 xl:pt-16">
          <div className=" w-full  flex flex-col  md:flex-row items-center justify-center">
            <div className=" w-full mt-28 md:mt-0 md:w-1/2 h-[90%] flex flex-col items-center md:justify-start">
              <h1 className="font-[sentient]  font-bold text-skyhero xl:text-7xl text-6xl md:text-6xl text-center md:text-left mt-16 md:leading-tight">
                Shree Lakshmi Water Purifiers
              </h1>
              <p className="w-full font-[sentient]  font-bold text-herosecondary text-center  md:text-left  text-5xl sm:text-6xl xl:text-7xl md:text-6xl md:leading-tight">
                #drinkpure
              </p>
            </div>
            <div className="w-full sm:w-3/4 md:h-[85%] md:w-1/2 pt-10 md:pt-0 flex items-center justify-center md:flex md:items-center md:justify-center">
              <img className="h-2/4 w-3/4" src={hero} alt="" srcset="" />
            </div>
          </div>
          <div className="hidden w-full md:flex justify-center items-center md:pt-20 lg:pt-10">
            <img src={mouse} alt="" srcset="" />
          </div>
        </div> */}
        <div className="second-page flex flex-col gap-5 mt-4 md:mt-8 lg:mt-0" id="whyuspage">
          <div className="w-full h-auto md:h-[25rem] md:flex-row flex flex-col gap-4">
            <p className=" font-[sentient] text-skyhero font-bold text-4xl text-center  lg:text-5xl lg:leading-tight xl:text-6xl w-full md:w-1/3 xl:leading-tight h-4/5 flex items-center justify-center">
              Why Shree Lakhsmi Water Purifiers?
            </p>
            <div className="w-full md:w-1/3 full bg-cardbg p-6 flex flex-col justify-center gap-5 shadow-xl rounded-xl">
              <p className="font-[Sentient] w-3/4 text-4xl  text-skyhero leading-tight">
                Premium Water Purification Technology
              </p>
              <div className="">
                <img className="mt-5" src={cardarrow} alt="" />
                <p className="font-[sentient] text-2xl">Learn more</p>
              </div>
            </div>
            <div className="w-full md:w-1/3 h-full bg-cardbg p-6 flex flex-col justify-center gap-5 shadow-xl rounded-xl">
              <p className="font-[sentient]  w-3/5 text-4xl text-skyhero">
                Trusted Sales and Service Store
              </p>
              <div className="">
                <img className="mt-5" src={cardarrow} alt="" />
                <p className="font-[sentient] text-2xl">Learn more</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[25rem] flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-[66.66%] h-full bg-skyhero p-6 flex flex-col justify-center gap-5 shadow-xl rounded-xl">
              <p className="font-[sentient]  text-3xl sm:text-4xl  text-white ">
                 Exclusive 2-Year AMC Offer
              </p>
              <p className="font-[sentient] text-white text-xl sm:text-2xl">
                Avail our exclusive 2-Year Annual Maintenance Contract (AMC) at
                a special price of 9999. Enjoy unlimited service calls, TDS
                maintenance every 3 months, 6-month Spun Free Spun Replacement,
                and inline filter replacements whenever required
              </p>
              <svg
                className="stroke-current text-white w-[15rem] sm:w-full md:w-full"
                width="343"
                height="21"
                viewBox="0 0 343 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line y1="0.5" x2="343" y2="0.5" />
                <line x1="324.628" y1="20.6655" x2="342.628" y2="0.665519" />
              </svg>
              <p className="font-[sentient] text-2xl  text-white">Learn more</p>
            </div>
            <div className="w-full md:w-1/3 h-full bg-cardbg p-6 flex flex-col justify-center gap-5 shadow-xl rounded-xl">
              <p className="font-[sentient] w-3/5 text-4xl text-skyhero ">
                Top Brands Applicable
              </p>
              <div className="">
                <img className="mt-5" src={cardarrow} alt="" />
                <p className="font-[sentient] text-2xl">Show our brands</p>
              </div>
            </div>
          </div>
          <p className="font-[sentient]  text-skyhero text-2xl w-full flex items-center justify-center">
            Show More
          </p>
        </div>

        <div
          className="third-page w-full  pt-36 flex flex-col gap-4"
          id="hotdeals"
        >
          <p className="font-[sentient] text-5xl text-skyhero">Hot Deals</p>
          <p className="font-[sentient] text-xl">
            Deals so hot that you can not resist!
          </p>
          <div className="h-[15rem] sm:h-[20rem] lg:h-[35rem] w-full flex gap-4 ">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              navigation={false}
              pagination={{ clickable: true }}
              modules={[Autoplay, Navigation, EffectFade, Pagination]}
              className="mySwiper swiperWhy"
            >
              <div className="flex items-center justify-center">
                {hotdealsPng.map((product, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {/* <HotdealsCards product={product} key={index} /> */}
                      <div  className="w-full h-full object-contain ">
                      <img className="object-contain w-full h-full pb-8" src={product.Image} alt="" />

                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>

            {/* <div className="items-cards w-full h-full border-[1px] border-black rounded-2xl flex">
            <div className="card-left h-full w-1/2 flex flex-col justify-center p-8 gap-4">
              <p className="font-[sentient] text-5xl text-skyhero  ">
                Purifier 1
              </p>
              <p className="font-[sentient] text-2xl text-cardbg">Brand name</p>
              <p className="font-[sentient] text-2xl ">
                Figma ipsum component variant main layer. Ellipse image frame
                group background align hand align.
              </p>
              <p className="font-[sentient] text-7xl text-ratecolor font-bold">
                ₹ 11 , 999/-
              </p>

              <svg
                className="stroke-current text-skyhero"
                width="343"
                height="21"
                viewBox="0 0 343 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line y1="0.5" x2="343" y2="0.5" />
                <line x1="324.628" y1="20.6655" x2="342.628" y2="0.665519" />
              </svg>
              <p className="font-[sentient] text-xl ">Contact the shop!</p>
            </div>
            <div className="cards-right relative h-full w-1/2">
              <img
                className="absolute z-0 top-0  right-14 h-[85%]"
                src={kentbg}
                alt=""
              />
              <img
                className="relative top-20 left-32 z-10 h-[85%]"
                src={kent}
                alt=""
              />
            </div> */}
            {/* </div>  */}
          </div>
        </div>
        <div 
          className="fourth-page mt-20 flex flex-col gap-4 pt-36"
          id="products"
        >
          <p className="font-[sentient] text-5xl text-skyhero">Our Store</p>
          <div className="flex justify-between">
          <p className="font-[sentient] text-xl">
            We sell a lot of Purifiers! here are a few of the best sellers by
            categories...
          </p>
          <p className="font-[sentient] text-xl underline cursor-pointer">
            View More...
          </p>
          
            
          </div>
          <div className="products-container shadow-2xl w-full h-[40rem] rounded-3xl flex">
            <div className="w-1/4 h-full shadow xl rounded-tl-3xl rounded-bl-3xl bg-products px-14 sm:flex flex-col gap-6 justify-center hidden">
              <p className={`font-[sentient] text-4xl cursor-pointer  ${selectedCategory==null ?"text-skyhero underline":"text-neutral-400"}`} onClick={() => handleCategoryClick(null)}>
                All Products
              </p>
         
              {productsButton.map((category) => (
                <p className={`font-[sentient]  text-2xl cursor-pointer ${selectedCategory==category.id ?"text-skyhero underline":"text-neutral-400"}`}
                  key={category.id}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.content}
                </p>
              ))}
            </div>
            <div className="h-full sm:w-3/4 flex gap-4 flex-wrap overflow-auto p-4 items-center justify-center">
              {!Loading ?(filteredProducts.map((products, index) => {
                return <ProductsCard product={products} key={index} />;
              })):<Loader/>
              }
            </div>
          </div>
        </div>
        <div
          className="fifth-page flex flex-col gap-4 mt-10 pt-36 "
          id="ourbrands"
        >
          <p className="font-[sentient] text-5xl text-skyhero">Our brands</p>
          <p className="font-[sentient] text-xl">
            We are represented by the brands that we sell...
          </p>
          <div className="w-full  flex gap-4 justify-center flex-wrap ">
            {brands.map((brand, index) => {
              return <Logocards logo={brand} key={index} />;
            })}
          </div>
          <p className="font-[sentient] text-xl text-right underline cursor-pointer">
          View More...
          </p>
        </div> 
        <div
          className="contactpage flex flex-col gap-4 mt-10 pt-36 "
          id="contact"
        >
          <p className="font-[sentient] text-5xl text-skyhero">Contact Us</p>
          <p className="font-[sentient] text-xl ">
            Visit the store today and get in touch with our customer
          </p>
          <div className="md:w-full flex gap-20 flex-wrap md:flex-nowrap ">
            <div className="w-full md:w-1/2 border border-1 border-black rounded-xl">
              <form
                className="flex flex-col items-center justify-center m-5 md:m-10 gap-4 "
                action=""
                onSubmit={contactForm.handleSubmit}
              >
                <input
                  className="p-4 w-full rounded-xl bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue]  focus:border-2 focus:border-blue-400"
                  type="name"
                  placeholder="Full Name"
                  name="fullName"
                  
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.fullName}
                />
                {contactForm.errors.fullName && contactForm.touched.fullName ? (
                  <p className="text-red-500 w-full pl-4">
                    {contactForm.errors.fullName}
                  </p>
                ) : null}
                <input
                  className="p-4 w-full rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue]  focus:border-2 focus:border-blue-400"
                  type="tel"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.phoneNumber}
                />
                {contactForm.errors.phoneNumber &&
                contactForm.touched.phoneNumber ? (
                  <p className="text-red-500 w-full pl-4">
                    {contactForm.errors.phoneNumber}
                  </p>
                ) : null}
                <input
                  className="p-4 w-full rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue]  focus:border-2 focus:border-blue-400"
                  type="tel"
                  placeholder="Alternate Phone Number"
                  name="alternatePhoneNumber"
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.alternatePhoneNumber}
                />
                {contactForm.errors.alternatePhoneNumber &&
                contactForm.touched.alternatePhoneNumber ? (
                  <p className="text-red-500 w-full pl-4">
                    {contactForm.errors.alternatePhoneNumber}
                  </p>
                ) : null}
                <input
                  className="p-4 w-full rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue]  focus:border-2 focus:border-blue-400"
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.subject}
                />
                {contactForm.errors.subject && contactForm.touched.subject ? (
                  <p className="text-red-500 w-full pl-4">
                    {contactForm.errors.subject}
                  </p>
                ) : null}
                <textarea
                  className="p-4 w-full rounded-xl h-[8rem] bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue] resize-none  focus:border-2 focus:border-blue-400"
                  placeholder="Please state your issue..."
                  id=""
                  cols="30"
                  rows="10"
                  name="issue"
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.issue}
                ></textarea>
                {contactForm.errors.issue && contactForm.touched.issue ? (
                  <p className="text-red-500 w-full pl-4">
                    {contactForm.errors.issue}
                  </p>
                ) : null}
                <button
                  className="p-4 w-full rounded-xl  bg-skyhero text-white font-[sentient]  placeholder:font-[Epilogue]"
                  type="submit"
                  disabled={false}
                >
                  {!loading ? <p>SUBMIT</p> : <Spinner />}
                </button>
              </form>
            </div>
            <div className="md:w-1/2  border border-3 border-spacing-5  border-black rounded-xl overflow-hidden flex flex-grow">
              {/* {isLoaded && (
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={10}
                  center={center}
                >
                  <Marker position={center} />
                </GoogleMap>
              )} */}
              <iframe
                className="md:w-full md:h-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.2335828888436!2d77.68062757454727!3d13.020791913798972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11668ba15beb%3A0x6e5db12464356e13!2sSHRI%20LAKSHMI%20WATER%20PURIFIERS!5e0!3m2!1sen!2sin!4v1711477283698!5m2!1sen!2sin"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div
          className="supportpage flex flex-col gap-4 mt-10 pt-36 "
          id="supportpage"
        >
          <p className="font-[sentient] text-5xl text-skyhero">Support</p>
          <p className="font-[sentient] text-xl w-full md:w-4/5">
            Raise complaint, our team will connect with you immediately to
            resolve the issue. if you havean emergency please contact our
            customer care number 7795453579.
          </p>
          <div className="md:w-full flex gap-20 flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/2  border border-3 border-spacing-5 border-black rounded-xl">
              <form
                className="flex flex-col items-center justify-center m-5 md:m-10 gap-4 "
                action=""
                onSubmit={supportForm.handleSubmit}
              >
                <input
                  className="p-4 w-full rounded-xl bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue] focus:border-2 focus:border-blue-400"
                  type="text"
                  placeholder="Full Name"
                  name="fullName"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.fullName}
                />
                {supportForm.errors.fullName && supportForm.touched.fullName ? (
                  <p className="text-red-500 w-full pl-4">
                    {supportForm.errors.fullName}
                  </p>
                ) : null}
                <input
                  className="p-4 w-full rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue] focus:border-2 focus:border-blue-400"
                  type="text"
                  placeholder="Registered Phone Number"
                  name="phoneNumber"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.phoneNumber}
                />
                {supportForm.errors.phoneNumber &&
                supportForm.touched.phoneNumber ? (
                  <p className="text-red-500 w-full pl-4">
                    {supportForm.errors.phoneNumber}
                  </p>
                ) : null}
                <input
                  className="p-4 w-full rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue] focus:border-2 focus:border-blue-400"
                  type="text"
                  placeholder="Bill Number"
                  name="billNumber"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.billNumber}
                />
                {supportForm.errors.billNumber &&
                supportForm.touched.billNumber ? (
                  <p className="text-red-500 w-full pl-4">
                    {supportForm.errors.billNumber}
                  </p>
                ) : null}
                <input
                  className="p-4 w-full rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue] focus:border-2 focus:border-blue-400"
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.subject}
                />
                {supportForm.errors.subject && supportForm.touched.subject ? (
                  <p className="text-red-500 w-full pl-4">
                    {supportForm.errors.subject}
                  </p>
                ) : null}
                <textarea
                  className="p-4 w-full h-[8rem] rounded-xl  bg-gray-200 outline-none font-[sentient] text-[#475569] placeholder:font-[Epilogue] resize-none focus:border-2 focus:border-blue-400"
                  placeholder="Please state your issue..."
                  name="issue"
                  id=""
                  cols="30"
                  rows="10"
                  onChange={supportForm.handleChange}
                  onBlur={supportForm.handleBlur}
                  value={supportForm.values.issue}
                ></textarea>
                {supportForm.errors.issue && supportForm.touched.issue ? (
                  <p className="text-red-500 w-full pl-4">
                    {supportForm.errors.issue}
                  </p>
                ) : null}
                <div
                  className="w-full h-36 bg-gray-200 rounded-xl flex  items-center justify-between px-12 gap-5 md:gap-0"
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleFile}
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32 32L24 24L16 32"
                      stroke="black"
                      stroke-opacity="0.4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24 24V42"
                      stroke="black"
                      stroke-opacity="0.4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M40.78 36.78C42.7307 35.7165 44.2717 34.0337 45.1597 31.9972C46.0478 29.9607 46.2324 27.6864 45.6844 25.5334C45.1364 23.3803 43.887 21.471 42.1333 20.1069C40.3797 18.7427 38.2217 18.0014 36 18H33.48C32.8746 15.6585 31.7463 13.4846 30.1799 11.642C28.6135 9.79927 26.6497 8.33567 24.4362 7.36118C22.2227 6.3867 19.8171 5.92669 17.4002 6.01573C14.9834 6.10478 12.6181 6.74057 10.4823 7.8753C8.34657 9.01003 6.49582 10.6142 5.06924 12.5671C3.64266 14.5201 2.67738 16.771 2.24596 19.1508C1.81454 21.5305 1.92821 23.977 2.57842 26.3065C3.22864 28.636 4.39848 30.7877 6 32.6"
                      stroke="black"
                      stroke-opacity="0.4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M32 32L24 24L16 32"
                      stroke="black"
                      stroke-opacity="0.4"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <label htmlFor="inoutTag">
                    <input
                      ref={fileRef}
                      className="hidden"
                      type="file"
                      placeholder="Browsefile"
                      name=""
                      id=""
                      onChange={(event) => {
                        setFile(event.target.files[0]);
                      }}
                    />

                    <div className="flex flex-col gap-2 items-center">
                      {" "}
                      <p className="hidden md:block">
                        Select a file or drag and drop here
                      </p>
                      <p className="hidden md:block">
                        JPG, PNG or PDF, file size no more than 10MB
                      </p>
                      {file ? (
                        <p className="">{file.name}</p>
                      ) : (
                        <div className="w-full flex items-center justify-center">
                          <p className="text-center text-sky-400 p-1 md:p-2 border-2 border-sky-400 1/4 rounded-md  text-sm ">
                            SELECT FILE
                          </p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                {!file ? (
                  <p className="text-red-500 w-full pl-4">Choose file</p>
                ) : (
                  ""
                )}
                <button
                  className="p-4 w-full rounded-xl  bg-skyhero text-white font-[sentient]  placeholder:font-[Epilogue] "
                  type="submit"
                >
                  {!loading ? <p>SUBMIT</p> : <Spinner />}
                </button>
              </form>
            </div>
            <div className="w-full md:w-1/2  border border-3 border-spacing-5  border-black rounded-xl overflow-hidden bg-[#609bf3c7] p-8 flex flex-grow">
              <p className="text-2xl md:text-4xl font-[sentient] md:w-4/5 mt-10">
                Shri Lakshmi Water Purifiers is not only dealing with water
                purifiers, also started Sales & Services for AC, Fridge, Washing
                Machine, Micro Wave Oven, Chimney, Stove and Home appliances
                sales and services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-background pt-20 pb-20">
        <div className="pt-36" id="aboutuspage">
          <Aboutus />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Homepage;

///box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
