import React from "react";
import logo from "..//..//Assets/logo.png";
import facebook from "..//..//Assets/Socialmedia/facebook.svg";
import insta from "..//..//Assets/Socialmedia/insta.svg";
import yt from "..//..//Assets/Socialmedia/yt.svg";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer bg-blue-100 font-[satoshi] md:flex md:flex-col px-28 py-20 gap-16 hidden">
      <div className="footer-container flex justify-between ">
        <div className="flex flex-col gap-8">
          <img className="h-12 w-12" src={logo} alt="" />
          <p className="md:w-1/2">
            Shop No: 3, First Floor, Kodi Krupa Complex, Ananda Pura Circle, TC
            Palya Main Road, K.R.Puram, Bangalore - 560049
          </p>
          <p>+91-7795453579</p>
          <div className="flex gap-4">
           

            
            <a href="https://www.facebook.com/profile.php?id=61558031230454" target="_blank" rel="noopener noreferrer"><img className="cursor-pointer" src={yt} alt="youtube" /></a>
            <a href="https://www.facebook.com/profile.php?id=61558031230454" target="_blank" rel="noopener noreferrer"><img className="cursor-pointer" src={facebook} alt="facebook" /></a>
            <a href="https://www.instagram.com/shrilakshmi99/" target="_blank" rel="noopener noreferrer"><img className="cursor-pointer" src={insta} alt="instagram" /></a>
          </div>
          {/* <div className="flex flex-col">
          <p className="underline"><HashLink smooth to="/#products">Water Purifier in Bangalore</HashLink></p>
          <p className="underline"><HashLink smooth to="/#products">Buy Aquaguard Water Purifier in Bangalore</HashLink></p>
          <p className="underline"><HashLink smooth to="/#products">Aquaguard water purifier service in Bangalore</HashLink></p>
          <p className="underline"><HashLink smooth to="/#products">Kent Water Purifier Service in Bangalore</HashLink></p>
          <p className="underline"><HashLink smooth to="/#products">Water purifier near me</HashLink></p>
          <p className="underline"><HashLink smooth to="/#products">Water filter service near me</HashLink></p>
          <p className="underline"><HashLink smooth to="/#products">Water purifier service</HashLink></p>
          </div> */}
        </div>
        <div className="flex gap-16">
          <div className="flex flex-col gap-4">
            <p className="font-bold">MENU</p>
            <ul className="flex flex-col gap-4">
              <li>
                <HashLink smooth to="/#contact">
                  Locations
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#aboutuspage">
                  About Us
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#hotdeals">
                  Pricing
                </HashLink>
              </li>
              {/* <li>Contact Us</li> */}
              <li>
                <HashLink smooth to="/#supportpage">
                  Support
                </HashLink>
              </li>
              <li>Blogs</li>
              <li>Success Stories</li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-bold ">OTHERS</p>
            <ul className="flex flex-col gap-4">
              <li>
                <HashLink smooth to="/#supportpage">
                  Raise Complaint
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#supportpage">
                  Raise Ticket
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#products">
                  Products
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#hotdeals">
                  Hot Deals
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#whyuspage">
                  Why Us?
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#ourbrands">
                  Our Brands
                </HashLink>
              </li>
            
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-bold">REVIEWS</p>
            <ul className="flex flex-col gap-4">
              <li>
                <a
                  href="https://g.page/r/CRNuNWQksV1uEB0/review"
                  target="_blank"
                >
                  Write a Review
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/search?q=SHRI+LAKSHMI+WATER+PURIFIERS&stick=H4sIAAAAAAAA_-NgU1I1qDBOSkw1NDQzs0hKNDRNSk2yMqgwSzVNSTI0MjEzMTY1SzU0XsQqE-wR5Kng4-gd7OHrqRDuGOIapBAQGuTp5ukaFAwANQDSZ0gAAAA&hl=en&mat=CQCnIZ66XYh5ElcBezTaAf2pwIA3QmQ3-W6ydIsgp3qEXucVF6dTti6u4qDC2CxQ02V7KetcsQhsEF0Q3WvnVJolC_OM9mU8nZqDLAL51RwEjGr8G2QnQgKP-pYkGfWRKDg&authuser=0#ip=1&lrd=0x3bae11668ba15beb:0x6e5db12464356e13,1,,,,1"
                  target="_blank"
                >
                  Read Reviews
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
          <p className="underline"><HashLink smooth to="/#products">Water Purifier in Bangalore </HashLink></p>
          <p>|</p>
          <p className="underline"><HashLink smooth to="/#products">Buy Aquaguard Water Purifier in Bangalore</HashLink></p>
          <p>|</p>
          <p className="underline"><HashLink smooth to="/#products">Aquaguard water purifier service in Bangalore</HashLink></p>
          <p>|</p>
          <p className="underline"><HashLink smooth to="/#products">Kent Water Purifier Service in Bangalore</HashLink></p>
          <p>|</p>
          <p className="underline"><HashLink smooth to="/#products">Water purifier near me</HashLink></p>
          <p>|</p>
          <p className="underline"><HashLink smooth to="/#products">Water filter service near me</HashLink></p>
          <p>|</p>
          <p className="underline"><HashLink smooth to="/#products">Water purifier service</HashLink></p>
          </div>
      <div className="footer-text flex justify-between text-sm">
        <p>© All rights reserved. Shri Lakshmi Water Purifiers</p>
        <ul className="flex gap-8">
          <li><Link smooth to = "/privacy-policy">Privacy Policy</Link></li>
          <li><Link smooth to="/terms">Terms & Conditions</Link></li>
          <li><Link smooth to="/return-policy">Product Returns policy</Link></li>
        </ul>
      </div>
    </div>
  );
}
