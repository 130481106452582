import React from 'react'

export default function ProductsCard({product}) {
  return (
   <div className=" h-auto w-[10rem] sm:h-auto sm:w-[19rem] border-2 border-skyhero flex flex-col gap-2 px-5 rounded-2xl justify-center relative overflow-hidden">
    <img className="h-[8rem] sm:h-[15rem] w-full" src={product.image} alt="" />
   <p className="font-[sentient] sm:text-2xl">{product.title}</p>
   <p className=" font-[sentient] sm:text-2xl text-skyhero">{product.brand}</p>
   <p className="font-[sentient] sm:text-4xl text-skyhero">₹ {product.discountedPrice}</p>
   <p className="font-[sentient] sm:text-xl text-neutral-400 line-through">₹ {product.price}</p>
   <p className="font-[Epilogue] sm:text-xl text-white absolute bg-skyhero bottom-0 right-0 p-2 sm:p-4 rounded-tl-2xl hidden sm:block ">Learn more</p>
   </div>
  )
}
